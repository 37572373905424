
/**
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
  if (target === null) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  if (target instanceof Array) {
    const cp = [] as any[];
    (target as any[]).forEach((v) => { cp.push(v); });
    return cp.map((n: any) => deepCopy<any>(n)) as any;
  }
  if (typeof target === 'object') {
    const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
    Object.keys(cp).forEach(k => {
      cp[k] = deepCopy<any>(cp[k]);
    });
    return cp as T;
  }
  return target;
};

/**
 * A function used to coerse a real boolean value from an @Input() intended to be a boolean value.
 * @returns boolean
 */
export function coerceBoolean(value: any): boolean {
  return value !== false && value !== void 0 && value !== null && value !== 0 && value !== 'false';
}

function _isObject(item: any): boolean {
  return (item === Object(item) && !Array.isArray(item));
}


export function deepMerge<T = Record<string, unknown>, R = T, S = R, Q = S, V = Q>(target: T, source1?: R, source2?: S, source3?: Q, source4?: V): T & R & S & Q & V {


  const sources: any[] = [source1, source2, source3, source4];

  // return the target if no sources passed
  if (!sources.length) {
    return target as T & R & S & Q & V;
  }

  const result = target;

  if (_isObject(result)) {
    const len: number = sources.length;

    for (let i = 0; i < len; i += 1) {
      const elm: any = sources[i];

      if (_isObject(elm)) {
        for (const key in elm) {
          // eslint-disable-next-line
          if (elm.hasOwnProperty(key)) {
            if (_isObject(elm[key])) {
              if (!result[key] || !_isObject(result[key])) {
                result[key] = {};
              }
              deepMerge(result[key], elm[key]);
            } else {
              if (Array.isArray(result[key]) && Array.isArray(elm[key])) {
                // concatenate the two arrays and remove any duplicate primitive values
                result[key] = Array.from(new Set(result[key].concat(elm[key])));
              } else {
                result[key] = elm[key];
              }
            }
          }
        }
      }
    }
  }

  return result as T & R & S & Q & V;
}



export function mergeListIntoOneDistinctItem<T = any>(
  list: T[],
  compareRecord?: Partial<Record<keyof T, (a: T, b: T) => boolean>>,
  keyFilter?: (keyof T)[]
): Partial<T> {

  let obj: Partial<T> = {};
  keyFilter ||= [];
  const keys = (Object.keys(list?.[0] || {}) as (keyof T)[]).filter(key => !(keyFilter.includes(key)));

  keys.forEach(key => {

    const compareFn = compareRecord?.[key];
    let isDistinct = false;

    if (compareFn) {
      isDistinct = list.every(item => compareFn(list[0], item));
    } else {
      const startValue = list[0][key];
      isDistinct = list.every(item => item[key] === startValue);
    }

    if (isDistinct) {
      obj[key] = list[0][key];
    }

  });

  return obj;
}

export function groupBy<T = any>(arr: T[], keyAccessorFn: (item: T) => string): Record<string, T[]> {
  return arr?.reduce((groups, item) => {
    (groups[keyAccessorFn(item)] ||= []).push(item);
    return groups;
  }, {});
}
